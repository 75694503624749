import { encryptUtils } from 'shared/utils'

export interface User {
    id: string
    mobile: string
    email: string
    ssn: string
}

export const languageData = [
    {
        value: 'en-US',
        label: 'English'
    },
    {
        value: 'zh-CN',
        label: '简体中文'
    },
    {
        value: 'zh-HK',
        label: '港澳繁体'
    }
]

export enum SIGN_STATUS_ENUM {
    SIGN_IN = 'sign_in',
    SIGN_IN_BY_EMAIL = 'sign_in_by_email',
    SIGN_UP = 'sign_up',
    SIGN_UP_BY_EMAIL = 'sign_up_by_email',
    FORGOT_PASSWORD = 'forgot_password',
    FORGOT_PASSWORD_EMAIL = 'forgot_password_by_email',
    TOURIST_BINGING = 'tourist_binging'
}

export interface LoginPost {
    loginName: string
    password: string
    nonce: string
    clientId: number
    grantType: string
}

export interface LoginParam {
    loginName: string
    password: string
}

export interface SignUpPost {
    mobile: string
    email: string
    password: string
    authCode: string
    aff?: string | null
}

export interface ForgetPasswordPost {
    mobile: string
    email: string
    password: string
    authCode: string
}

export interface AuthCodeResponse {
    exists: boolean
    needUpdateProfile: boolean
    resendToken: string
}

export type verificationCodeType = 'signUp' | 'forgetPassword' | 'bind' | 'thirdBind'

interface EncryptPasswordRes {
    password: string
    nonce: string
}

const IV_KEY = 'a0fe7c7c98e09e8c'

export const encryptPasswordByCustomIV = (pwd: string, iv: string): EncryptPasswordRes => {
    const nonce = encryptUtils.generateKey(16)
    const password = encryptUtils.encrypt(pwd, nonce, iv)
    return {
        password,
        nonce
    }
}

export const encryptPassword = (pwd: string): EncryptPasswordRes => encryptPasswordByCustomIV(pwd, IV_KEY)

export interface ChangePwdFormData {
    oldPassword: string
    nonce: string
    newPassword: string
}

export interface UserInfo {
    userName: string
    status: string
    email: string
    phone: string
    link: string
    commissionRate: string
    shareClickCount: string
    shareRegisterCount: string
    totalAmount: string
    withdrawAmount: string
    ingAmount: string
    storeAdmin: boolean
    role: {
        deviceAdmin: boolean
        nftSuperAdmin: boolean
        adAdmin?: boolean
    }
}
