import { AuthCodeResponse } from 'core/auth/models'

export interface loginDTO {
    accessToken: string
    walletToken: string
    walletAddress: string
}

export interface AuthCodeResponseDTO {
    exists: boolean
    needUpdateProfile: boolean
    resendToken: string
}

export const convertToAuthCode = (dto: AuthCodeResponseDTO): AuthCodeResponse | string => dto

export interface getUserInfoDTO {
    loginName: string
    userName: string
    status: string
    email: string
    phone: string
    link: string
    commissionRate: string
    shareClickCount: string
    shareRegisterCount: string
    totalAmount: number
    withdrawAmount: number
    ingAmount: number
    storeAdmin: boolean
    userid: string
    role: {
        deviceAdmin: boolean
        nftSuperAdmin: boolean
    }
}
